
export default {
	name: 'subjects',
	data() {
		return {
			sex: '',
			work: {
				crcId: '', //crcId
				patientName: '',//受试者名字
				currentType: '',//所在阶段
				schemeName: '',//执行方案
				time: '',//入组日期
				crcName: ''
			},
			outId: "",
			alertTitle: '知情同意书', //弹框title
			list: "",
			color(value) {
				if (value < 20) {
					return '#F73B36'
				} else if (value >= 20 && value < 80) {
					return '#FFAE00'
				} else {
					return '#36B822'
				}
			},
			ulList: '',
			subjectsList: []
		}
	},
	created() {
		this.token = this.getCookie('token')
		this.getCard()
	},
	components: {
		Top,
		Left
	},
	methods: {
		getCard() {
			var _this = this;
			_this.work.crcId = _this.getCookie('crcId');//crcID
			_this.$http.post('/API/PLAT/patientPlanDetails', {
				patientId: _this.$route.params.patientId,
				projectId: _this.$route.params.projectId,
				crcId: _this.work.crcId
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						_this.work.crcName = response.data.data.crcName;//受试者名字
						_this.work.patientName = response.data.data.patientName;//受试者名字
						_this.subjectsList = response.data.data.projectArray;
						_this.work.time = response.data.data.joinDate;
						_this.work.schemeName = response.data.data.schemeName;//执行方案
						_this.work.currentType = response.data.data.currentType;//所在阶段
					}
				})
		},
		clickCard(checkid, workid, checkTemplateId) {
			var _this = this;
			this.partAlert = true;
			_this.$http.post('/API/PROJECT/patListByCheckIdAmdWorkId', {
				workid: workid,
				checkid: checkid,
				checkTemplateId: checkTemplateId
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						//						console.log(response)
						_this.alertTitle = response.data.data.checkName;
						_this.ulList = response.data.data.patList;
					}
				})
		},
		toPl() {//面包屑
			this.$router.go(-1)
		},
		no() {
			this.removeAlert = false;
		},
	}
}
